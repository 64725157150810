import request from './request';

const defaultRequest = request();

export function getCostTypes() {
  return defaultRequest({
    url: 'api/v2/costtype-autocomplete',
    method: 'get'
  });
}

export function getCosts(params) {
  return defaultRequest({
    url: 'api/v2/costs',
    method: 'get',
    params
  });
}

export function getCostDetail(id) {
  return defaultRequest({
    url: `api/v2/costs/${id}`,
    method: 'get'
  });
}

export function addCostToTrip(data) {
  return defaultRequest({
    url: 'api/v2/costs',
    method: 'post',
    data
  });
}

export function addImagesToCost(data) {
  return defaultRequest({
    url: 'api/v2/image-urls/costs',
    method: 'post',
    data
  });
}

export function updateCost(data) {
  return defaultRequest({
    url: `api/v2/costs/${data.id}`,
    method: 'put',
    data
  });
}

export function deleteCost(id) {
  return defaultRequest({
    url: `api/v2/costs/${id}`,
    method: 'delete'
  });
}
