import axios from 'axios';
import store from '../store';
import * as Auth from '../utils/auth';
import router from '../router';

const service = baseUrl => {
  const instance = axios.create({
    baseURL: baseUrl ? baseUrl : process.env.BASE_API,
    timeout: 600000
  });

  instance.interceptors.request.use(
    config => {
      if (store.getters.token) {
        config.headers['Authorization'] = `Bearer ${Auth.getAccessToken()}`;
      }
      return config;
    },
    error => {
      console.error(error);
      Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    response => {
      if (!response.config.headers.Authorization)
        window.location.href = Auth.getURLAuthorize();
      return response;
    },
    error => {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const accessToken = Auth.getAccessToken();
        const refreshToken = Auth.getRefreshToken();

        if (!accessToken && !refreshToken) {
          window.location.href = Auth.getURLAuthorize();
        } else {
          store
            .dispatch('refreshToken', refreshToken)
            .then(res => {
              if (res.accessToken) {
                store.commit('SET_TOKEN', res.accessToken);
                axios.defaults.headers.common['Authorization'] =
                  'Bearer ' + res.accessToken;
                originalRequest.headers['Authorization'] =
                  'Bearer ' + res.accessToken;

                window.location.reload();
              } else {
                window.location.href = Auth.getURLAuthorizeWithForce();
              }
              if (res.code) {
                setTimeout(() => {
                  window.location.href = Auth.getURLAuthorizeWithForce();
                }, 3000);
              }
            })
            .catch(error => {
              window.location.href = Auth.getURLAuthorizeWithForce();
            });
        }
      }

      if (error.response && error.response.status === 403) {
        router.push('/403');
      }
      if (error.response.status === 500) {
        console.error(error);
        return Promise.reject(error);
      }

      // console.error(error);
      return Promise.reject(error.response);
    }
  );
  return instance;
};

export default service;
