export function compressImageFile(url) {
  let img = new Image();
  img.src = url;
  return new Promise((resolve, reject) => {
    img.onload = e => {
      const width = Math.round(img.width / 2);
      const height = Math.round(img.height / 2);
      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, width, height);

      let result = canvas.toDataURL('image/jpeg', 0.5);

      // var head = 'data:image/jpeg;base64,';
      // var imgFileSize = Math.round(((result.length - head.length) * 3) / 4);
      // console.log('compress -> imgFileSize', imgFileSize);

      resolve(result);
    };
  });
}

export function formatBase64toBinary(dataURI) {
  if (!dataURI) return null;
  var byteString = atob(dataURI.split(',')[1]);
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);

  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: 'image/jpeg' });
}

export function formatBase64(dataURI) {
  if (!dataURI) return dataURI;
  return dataURI.split(',')[1];
}
