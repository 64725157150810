import Vue from 'vue';
import Vuex from 'vuex';
import getters from './getters';
import user from './modules/user';
import trip from './modules/trip';
import cost from './modules/cost';
import fuel from './modules/fuel';
import geocode from './modules/geocode';
import uploadFile from './modules/upload-file';
import fuelDocument from './modules/fuel-document';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    user,
    trip,
    cost,
    fuel,
    geocode,
    uploadFile,
    fuelDocument
  },
  getters
});

export default store;
