import * as user from './user';
import * as trip from './trip';
import * as cost from './cost';
import * as fuel from './fuel';
import * as geocode from './geocode';
import * as uploadFile from './upload-file';
import * as fuelDocument from './fuel-document';

const API = { user, trip, cost, fuel, geocode, uploadFile, fuelDocument };

export default API;
