const AccessTokenKey = 'Access-Token';
const RefreshTokenKey = 'Refresh-Token';
const LoggedUser = 'LoggedUserInfo';
const ClientId = 'Client-Id';
import { ApiUrl } from './index';
import { Cookies } from 'quasar';

export function getURLAuthorize() {
  let returnLink = setURL(window.location.href);
  let url = ApiUrl.Login;
  url += '?appId=' + ApiUrl.AppId;
  url += '&returnUrl=' + encodeURIComponent(returnLink);
  return url;
}

export function getURLAuthorizeWithForce() {
  let returnLink = setURL(window.location.href);
  let url = ApiUrl.Login;
  url += '?force=true&appId=' + ApiUrl.AppId;
  url += '&returnUrl=' + encodeURIComponent(returnLink);
  return url;
}

export function getAccessToken() {
  let token = localStorage.getItem(AccessTokenKey);
  return token ? token : Cookies.get(AccessTokenKey);
}

export function setAccessToken(token) {
  localStorage.setItem(AccessTokenKey, token);
  return Cookies.set(AccessTokenKey, token);
}

export function getRefreshToken() {
  let token = localStorage.getItem(RefreshTokenKey);
  return token ? token : Cookies.get(RefreshTokenKey);
}

export function setRefreshToken(token) {
  localStorage.setItem(RefreshTokenKey, token);
  return Cookies.set(RefreshTokenKey, token);
}

export function setURL(link) {
  let index =
    link.indexOf('?appId') ||
    link.indexOf('?accesstoken') ||
    link.indexOf('?refreshtoken') ||
    link.indexOf('?clientId');
  if (index < 0) return link.replace('403', '');
  let str = link.slice(index, link.length);
  return link.replace(str, '').replace('403', '');
}

export function removeToken() {
  localStorage.removeItem(RefreshTokenKey);
  localStorage.removeItem(AccessTokenKey);
  Cookies.remove(RefreshTokenKey);
  return Cookies.remove(AccessTokenKey);
}

export function getClientId() {
  let id = localStorage.getItem(ClientId);
  return id ? id : Cookies.get(ClientId);
}

export function setClientId(id) {
  localStorage.setItem(ClientId, id);
  Cookies.set(ClientId, id);
}

export function removeClientId() {
  localStorage.removeItem(ClientId);
  Cookies.remove(ClientId);
}

export function getLoggedUser() {
  let userFromLocalStorage = localStorage.getItem(LoggedUser);
  let userFromCookie = Cookies.get(LoggedUser);

  return userFromLocalStorage
    ? JSON.parse(userFromLocalStorage)
    : userFromCookie
    ? userFromCookie
    : null;
}

export function setLoggedUser(user) {
  let json = JSON.stringify(user);
  localStorage.setItem(LoggedUser, json);
  return Cookies.set(LoggedUser, json);
}

export function removeLoggedUse() {
  localStorage.removeItem(LoggedUser);
  Cookies.remove(LoggedUser);
}
