import API from '@/api/index';

const geocode = {
  state: {},

  mutations: {},

  actions: {
    getAddress({ commit }, data) {
      return new Promise((resolve, reject) => {
        API.geocode
          .getAddress(data)
          .then(response => {
            let result = response.data;
            resolve(result.data);
          })
          .catch(err => {
            reject(err);
          });
      });
    }
  }
};

export default geocode;
