import API from '@/api/index';

const cost = {
  state: {},

  mutations: {},

  actions: {
    getCostTypes({ commit }) {
      return new Promise((resolve, reject) => {
        API.cost
          .getCostTypes()
          .then(response => {
            let result = response.data;
            resolve(result.data);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    getCosts({ commit }, data) {
      return new Promise((resolve, reject) => {
        API.cost
          .getCosts(data)
          .then(response => {
            let result = response.data;
            resolve(result.data);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    getCostDetail({ commit }, data) {
      return new Promise((resolve, reject) => {
        API.cost
          .getCostDetail(data)
          .then(response => {
            let result = response.data;
            resolve(result.data);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    addCostToTrip({ commit }, data) {
      return new Promise((resolve, reject) => {
        API.cost
          .addCostToTrip(data)
          .then(response => {
            let result = response.data;
            if (!result.code && result.data) resolve(result.data);
            else reject(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    addImagesToCost({ commit }, data) {
      return new Promise((resolve, reject) => {
        API.cost
          .addImagesToCost(data)
          .then(response => {
            let result = response.data;
            if (!result.code && result.data) resolve(result.data);
            else reject(result);
          })
          .catch(err => {
            reject(err.data);
          });
      });
    },

    updateCost({ commit }, data) {
      return new Promise((resolve, reject) => {
        API.cost
          .updateCost(data)
          .then(response => {
            let result = response.data;
            if (!result.code && result.data) resolve(result.data);
            else reject(result);
          })
          .catch(err => {
            reject(err.data);
          });
      });
    },

    deleteCost({ commit }, data) {
      return new Promise((resolve, reject) => {
        API.cost
          .deleteCost(data)
          .then(response => {
            let result = response.data;
            if (!result.code && result.data) resolve(result.data);
            else reject(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    }
  }
};

export default cost;
