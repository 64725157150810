import request from './request';

import { formatBase64 } from '@/utils/file';

const defaultRequest = request();

export function uploadFileImage(file) {
  let data = {
    file: formatBase64(file)
  };

  return defaultRequest({
    url: 'api/v2/upload-file',
    method: 'post',
    data
  });
}

export function uploadMultipleFile(data) {
  return defaultRequest({
    url: 'api/v2/uploads',
    method: 'post',
    data
  });
}

export function uploadAvatar(data) {
  return defaultRequest({
    url: 'api/v2/image-urls/upload',
    method: 'post',
    data
  });
}

export function deleteImageUrl(id) {
  return defaultRequest({
    url: `api/v2/image-urls/${id}`,
    method: 'delete'
  });
}
