import request from './request';

const defaultRequest = request();

export function getAddress(params) {
  console.log('🚀 ~ file: geocode.js ~ line 6 ~ getAddress ~ params', params);
  return defaultRequest({
    url: 'api/v2/geocode/get-address',
    method: 'get',
    params
  });
}
