import * as Auth from '../utils/auth';
import request from './request';

const defaultRequest = request();
const tokenRequest = request(process.env.REFRESH_TOKEN_URL);

export function getUserProfile() {
  return defaultRequest({
    url: `api/v2/users/profile`,
    method: 'get'
  });
}

export function refreshToken(refreshToken) {
  let data = {
    refreshToken: refreshToken,
    clientId: Auth.getClientId()
  };
  return tokenRequest({
    url: `User/refreshtoken`,
    method: 'post',
    data
  });
}
