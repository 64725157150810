import request from './request';

const defaultRequest = request();

export function getTrips(params) {
  return defaultRequest({
    url: 'api/v2/trips',
    method: 'get',
    params
  });
}

export function getTripDetail(id) {
  return defaultRequest({
    url: `api/v2/trips/${id}`,
    method: 'get'
  });
}

export function getSummary() {
  return defaultRequest({
    url: `api/v2/trips/report`,
    method: 'get'
  });
}

export function getCurentInfo(params) {
  return defaultRequest({
    url: `api/v2/trips/get-info`,
    method: 'get',
    params
  });
}

export function createNewTrip(data) {
  return defaultRequest({
    url: '/api/v2/trips',
    method: 'post',
    data
  });
}

export function updateTrip(data) {
  return defaultRequest({
    url: `/api/v2/trips/${data.id}`,
    method: 'put',
    data
  });
}

export function pauseTrip(data) {
  return defaultRequest({
    url: `/api/v2/trips/idle`,
    method: 'put',
    data
  });
}

export function continueTrip(data) {
  return defaultRequest({
    url: `/api/v2/trips/continue`,
    method: 'put',
    data
  });
}
