export let ApiUrl = {
  AppId: 'driver',
  ClientId: 'driver.client',
  Login: process.env.LOGIN_URL
};

export const AppClientIDs = {
  Tracking: 'tracking.client',
  Navi: 'navi.client',
  Motrak: 'motrak.client',
  SC: 'sc.tracking.client'
};
