const routes = [
  {
    path: '/',
    component: () => import('layouts/default.vue'),
    children: [{ path: '', component: () => import('pages/Home.vue') }]
  },
  {
    path: '/trips',
    component: () => import('layouts/default.vue'),
    children: [
      { path: '', component: () => import('pages/trips/Index.vue') },
      {
        path: ':id',
        component: () => import('pages/trips/Detail.vue')
      }
    ]
  },
  {
    path: '/cost-list',
    component: () => import('layouts/default.vue'),
    children: [
      { path: '', component: () => import('pages/cost-list/Index.vue') },
      {
        path: ':id',
        component: () => import('pages/cost-list/Detail.vue')
      }
    ]
  },
  {
    path: '/fuel-list',
    component: () => import('layouts/default.vue'),
    children: [
      { path: '', component: () => import('pages/fuel-list/Index.vue') },
      {
        path: ':id',
        component: () => import('pages/fuel-list/Detail.vue')
      }
    ]
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '*',
    component: () => import('pages/Error404.vue')
  }
];

export default routes;
