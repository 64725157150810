import API from '@/api/index';

const fuel = {
  state: {},

  mutations: {},

  actions: {
    getFuelTypes({ commit }) {
      return new Promise((resolve, reject) => {
        API.fuel
          .getFuelTypes()
          .then(response => {
            let result = response.data;
            resolve(result.data);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    getFuels({ commit }, data) {
      return new Promise((resolve, reject) => {
        API.fuel
          .getFuels(data)
          .then(response => {
            let result = response.data;
            resolve(result.data);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    getFuelDetail({ commit }, data) {
      return new Promise((resolve, reject) => {
        API.fuel
          .getFuelDetail(data)
          .then(response => {
            let result = response.data;
            resolve(result.data);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    createFuel({ commit }, data) {
      return new Promise((resolve, reject) => {
        API.fuel
          .createFuel(data)
          .then(response => {
            let result = response.data;
            if (!result.code && result.data) resolve(result.data);
            else reject(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    addImagesToFuel({ commit }, data) {
      return new Promise((resolve, reject) => {
        API.fuel
          .addImagesToFuel(data)
          .then(response => {
            let result = response.data;
            if (!result.code && result.data) resolve(result.data);
            else reject(result);
          })
          .catch(err => {
            reject(err.data);
          });
      });
    },

    updateFuel({ commit }, data) {
      return new Promise((resolve, reject) => {
        API.fuel
          .updateFuel(data)
          .then(response => {
            let result = response.data;
            if (!result.code && result.data) resolve(result.data);
            else reject(result);
          })
          .catch(err => {
            reject(err.data);
          });
      });
    },

    deleteFuel({ commit }, data) {
      return new Promise((resolve, reject) => {
        API.fuel
          .deleteFuel(data)
          .then(response => {
            let result = response.data;
            if (!result.code && result.data) resolve(result.data);
            else reject(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    }
  }
};

export default fuel;
