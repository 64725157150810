import API from '@/api/index';

const trip = {
  state: {
    diaryTrips: [],
    currentInfo: null,
    currentLocalPosition: null,
    currentLocation: null
  },

  mutations: {
    SET_DIARY_TRIPS: (state, data) => {
      state.diaryTrips = [...data];
    },

    SET_CURRENT_INFO: (state, currentInfo) => {
      state.currentInfo = currentInfo;
    },

    SET_CURRENT_LOCAL_POSITION: (state, data) => {
      state.currentLocalPosition = data;
    },

    SET_CURRENT_LOCATION: (state, data) => {
      state.currentLocation = data;
    }
  },

  actions: {
    getTrips({ commit }, data) {
      return new Promise((resolve, reject) => {
        API.trip
          .getTrips(data)
          .then(response => {
            let result = response.data;
            resolve(result.data);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    getTripDetail({ commit }, data) {
      return new Promise((resolve, reject) => {
        API.trip
          .getTripDetail(data)
          .then(response => {
            let result = response.data;
            resolve(result.data);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    getSummary({ commit }) {
      return new Promise((resolve, reject) => {
        API.trip
          .getSummary()
          .then(response => {
            let result = response.data;
            resolve(result.data);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    getCurentInfo({ commit }, data) {
      return new Promise((resolve, reject) => {
        API.trip
          .getCurentInfo(data)
          .then(response => {
            let result = response.data;
            commit('SET_CURRENT_INFO', result.data);
            resolve(result.data);
          })
          .catch(err => {
            commit('SET_CURRENT_INFO', null);
            reject(err);
          });
      });
    },

    createNewTrip({ commit }, data) {
      return new Promise((resolve, reject) => {
        API.trip
          .createNewTrip(data)
          .then(response => {
            let result = response.data;
            if (!result.code && result.data) {
              commit('SET_CURRENT_INFO', result.data);
              resolve(result.data);
            } else reject(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    updateTrip({ commit }, data) {
      return new Promise((resolve, reject) => {
        API.trip
          .updateTrip(data)
          .then(response => {
            let result = response.data;
            if (!result.code && result.data) {
              resolve(result.data);
            } else reject(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    pauseTrip({ commit }, data) {
      return new Promise((resolve, reject) => {
        API.trip
          .pauseTrip(data)
          .then(response => {
            let result = response.data;
            if (!result.code && result.data) {
              resolve(result.data);
            } else reject(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    continueTrip({ commit }, data) {
      return new Promise((resolve, reject) => {
        API.trip
          .continueTrip(data)
          .then(response => {
            let result = response.data;
            if (!result.code && result.data) {
              resolve(result.data);
            } else reject(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    }
  }
};

export default trip;
