import API from '@/api/index';
import * as Auth from '@/utils/auth';

const user = {
  state: {
    token: Auth.getAccessToken(),
    refreshToken: Auth.getRefreshToken(),
    tokenType: null,
    keyword: null,
    clientID: null,
    loggedUser: null
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
      Auth.setAccessToken(token);
    },

    SET_TOKEN_TYPE: (state, tokenType) => {
      state.tokenType = tokenType;
    },

    SET_REFRESH_TOKEN: (state, refreshToken) => {
      state.refreshToken = refreshToken;
      Auth.setRefreshToken(refreshToken);
    },

    SET_KEYWORD: (state, keyword) => {
      state.keyword = keyword;
    },

    SET_CLIENT_ID: (state, clientID) => {
      state.clientID = clientID;
      Auth.setClientId(clientID);
    },

    SET_LOGGED_USER: (state, loggedUser) => {
      let localUser = Auth.getLoggedUser();
      let currentVehicle = localUser && localUser.currentVehicle;
      if (!loggedUser.vehicles || !loggedUser.vehicles.length) {
        currentVehicle = null;
      } else {
        if (loggedUser.vehicles.length === 1)
          currentVehicle = loggedUser.vehicles[0];
        else {
          currentVehicle = loggedUser.vehicles.find(
            item =>
              currentVehicle && currentVehicle.vehicleId === item.vehicleId
          );
        }
      }

      state.loggedUser = { ...loggedUser, ...{ currentVehicle } };
      Auth.setLoggedUser(state.loggedUser);
    },

    SET_CURRENT_VEHICLE: (state, currentVehicle) => {
      state.loggedUser = { ...state.loggedUser, ...{ currentVehicle } };
      Auth.setLoggedUser(state.loggedUser);
    }
  },

  actions: {
    logIn({ commit }, data) {
      return new Promise(resolve => {
        commit('SET_TOKEN', data.token);
        commit('SET_REFRESH_TOKEN', data.refreshToken);

        resolve();
      });
    },

    refreshToken({ commit }, data) {
      return new Promise((resolve, reject) => {
        API.user
          .refreshToken(data)
          .then(response => {
            let result = response.data;
            if (!result.code && result.data) {
              let token = result.data.accessToken;
              commit('SET_TOKEN', token);
              commit('SET_TOKEN_TYPE', result.data.tokenType);
              commit('SET_REFRESH_TOKEN', result.data.refreshToken);
              resolve(result.data);
            } else {
              reject(response.data);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    logOut({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '');
        commit('SET_REFRESH_TOKEN', '');

        Auth.removeToken();
        Auth.removeLoggedUse();
        resolve();
      });
    },

    getUserProfile({ commit }) {
      return new Promise((resolve, reject) => {
        API.user
          .getUserProfile()
          .then(response => {
            let result = response.data;
            if (!result.error) {
              commit('SET_LOGGED_USER', result.data);
              resolve(result.data);
            } else {
              reject(null);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    }
  }
};

export default user;
