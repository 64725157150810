const getters = {
  //user
  token: state => state.user.token,
  refreshToken: state => state.user.refreshToken,
  loggedUser: state => state.user.loggedUser,
  clientID: state => state.user.clientID,
  keyword: state => state.user.keyword,

  //trip
  diaryTrips: state => state.trip.diaryTrips,
  currentInfo: state => state.trip.currentInfo,
  currentLocalPosition: state => state.trip.currentLocalPosition,
  currentLocation: state => state.trip.currentLocation
};
export default getters;
