<template>
  <div id="q-app">
    <router-view />
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import * as Auth from './utils/auth';

export default {
  name: 'App',

  created() {
    this.getAccessToken();
  },

  mounted() {
    window.addEventListener('load', () => {
      this.getUser();
      if (
        this.$route.query.appId ||
        this.$route.query.accesstoken ||
        this.$route.query.refreshtoken ||
        this.$route.query.clientId
      )
        window.location.href = Auth.setURL(window.location.href);
    });
  },

  methods: {
    ...mapActions(['logIn', 'getUserProfile']),
    ...mapMutations(['SET_CLIENT_ID']),

    getAccessToken() {
      let link = window.location.href;
      let accessToken = link
        .slice(link.indexOf('accesstoken'), link.indexOf('&refreshtoken'))
        .replace('accesstoken=', '');
      let refreshtoken = link
        .slice(link.indexOf('refreshtoken'), link.indexOf('&clientId'))
        .replace('refreshtoken=', '');
      if (accessToken && refreshtoken) {
        let data = {
          token: accessToken,
          refreshToken: refreshtoken
        };
        this.logIn(data);
      }

      if (link.indexOf('clientId') !== -1) {
        let clientId = link
          .slice(link.indexOf('clientId'), link.length)
          .replace('clientId=', '');
        if (clientId && clientId !== '/') this.SET_CLIENT_ID(clientId);
      }
    },

    //Lấy thông tin user đã login
    async getUser() {
      let clientId = Auth.getClientId();
      let res = await this.getUserProfile(clientId).catch(err => {
        return false;
      });
      if (res) this.user = res && res.profile;
    }
  }
};
</script>
