import Vue from 'vue';
import XLSX from 'xlsx';
import { Notify } from 'quasar';
import moment from 'moment';
import { date, Loading, QSpinnerDots } from 'quasar';
import { mapMutations, mapActions, mapGetters } from 'vuex';

const mixinGlobal = {
  data: () => ({
    // scrollHeight: 0
  }),

  mounted() {},

  computed: {
    ...mapGetters(['currentLocalPosition', 'loggedUser'])
  },

  methods: {
    ...mapMutations(['SET_CURRENT_LOCAL_POSITION', 'SET_CURRENT_LOCATION']),
    ...mapActions(['getAddress', 'getCurentInfo']),

    //Xuất file excel
    exportFileExcel(data, fileName) {
      let ws = XLSX.utils.json_to_sheet(data);
      let wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, this.fileName);
      XLSX.writeFile(wb, `${fileName}.xlsx`);
    },

    showMessage(message, type) {
      let icon = null;
      let color = 'primary';
      let badgeColor = 'indigo';
      if (type === 'error') {
        color = 'red';
        icon = 'error';
        badgeColor = 'negative';
      } else if (type === 'success') {
        color = 'green';
        icon = 'done';
        badgeColor = 'green-9';
      }
      Notify.create({
        message: message,
        color: color,
        icon: icon,
        actions: [{ icon: 'close', color: 'white' }],
        badgeColor
      });
    },

    getArryDate(from, to) {
      let end = to;
      let array = [];
      while (new Date(end) >= new Date(from)) {
        array.push(date.formatDate(new Date(end), 'DD/MM/YYYY'));
        end = date.subtractFromDate(new Date(end), { days: 1 });
      }

      return array;
    },

    showLoading() {
      Loading.show({
        spinner: QSpinnerDots
      });
    },

    hideLoading() {
      Loading.hide();
    },

    navigateLocation() {
      if (navigator.geolocation) {
        return new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(
            position => {
              this.getPosition(position);
              resolve(true);
            },
            error => {
              this.showError(error);
              reject(false);
            }
          );
        });
      }
    },

    async getPosition(position) {
      let origin = {
        lat: position.coords.latitude,
        lon: position.coords.longitude
      };
      this.SET_CURRENT_LOCAL_POSITION(origin);
    },

    showError(error) {
      switch (error.code) {
        case error.PERMISSION_DENIED:
          this.showMessage('Yêu cầu cho phép truy cập vị trí', 'error');
          break;
        case error.POSITION_UNAVAILABLE:
          this.showMessage('Thông tin vị trí không có sẵn', 'error');
          break;
        case error.TIMEOUT:
          this.showMessage('Yêu cầu vị trí người dùng đã hết hạn', 'error');
          break;
        case error.UNKNOWN_ERROR:
          this.showMessage('Lỗi không xác định', 'error');
          break;
      }
    },

    async getInfo() {
      if (
        !this.loggedUser.currentVehicle ||
        !this.loggedUser.currentVehicle.vehicleId
      ) {
        this.showMessage('Vui lòng chọn xe để lấy vị trí!', 'error');
        return;
      }

      //Lấy vị trí hiện tại của tài xế qua app
      await this.navigateLocation();

      let currentLocal = this.currentLocalPosition;
      currentLocal.vehicleId = this.loggedUser.currentVehicle.vehicleId;

      await this.getCurentInfo(currentLocal);
    }
  }
};

export default mixinGlobal;
Vue.mixin(mixinGlobal);
