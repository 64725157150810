import request from './request';

const defaultRequest = request();

export function getFuelTypes() {
  return defaultRequest({
    url: 'api/v2/fueltype-autocomplete',
    method: 'get'
  });
}

export function getFuels(params) {
  return defaultRequest({
    url: 'api/v2/fuels',
    method: 'get',
    params
  });
}

export function getFuelDetail(id) {
  return defaultRequest({
    url: `api/v2/fuels/${id}`,
    method: 'get'
  });
}

export function createFuel(data) {
  return defaultRequest({
    url: 'api/v2/fuels',
    method: 'post',
    data
  });
}

export function addImagesToFuel(data) {
  return defaultRequest({
    url: 'api/v2/image-urls/fuels',
    method: 'post',
    data
  });
}

export function updateFuel(data) {
  return defaultRequest({
    url: `api/v2/fuels/${data.id}`,
    method: 'put',
    data
  });
}

export function deleteFuel(id) {
  return defaultRequest({
    url: `api/v2/fuels/${id}`,
    method: 'delete'
  });
}
