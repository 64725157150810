import API from '@/api/index';

const fuelDocument = {
  state: {},

  mutations: {},

  actions: {
    getFuelProvideDocumentByVehicle({ commit }, data) {
      return new Promise((resolve, reject) => {
        API.fuelDocument
          .getFuelProvideDocumentByVehicle(data)
          .then(response => {
            let result = response.data;
            resolve(result.data);
          })
          .catch(err => {
            reject(err);
          });
      });
    }
  }
};

export default fuelDocument;
