import request from './request';

const defaultRequest = request();

export function getFuelProvideDocumentByVehicle(params) {
  return defaultRequest({
    url: 'api/v2/fuel-provide-documents/vehicle',
    method: 'get',
    params
  });
}
